import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    query contentful {
      allContentfulPost {
        edges {
          node {
            id
            title
            content {
              content
            }
          }
        }
      }
    }
  `);

  //console.log('data', JSON.parse(data.allContentfulPost.edges[0].node.content.content));
  return <div>test</div>;
};
